define("sharedrop/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BRX2BmjE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"l-header\"],[12],[2,\"\\n  \"],[10,\"nav\"],[14,0,\"navbar\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"logo\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"logo-title\"],[12],[2,\"ShareDrop\"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"ul\"],[14,0,\"nav\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"javascript:void(0)\"],[24,5,\"color: white;\"],[24,0,\"icon-create-room\"],[24,\"title\",\"Create a room. You'll leave the room you're currently in.\"],[4,[38,0],[[32,0],\"redirect\"],null],[12],[2,\"+\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"javascript:void(0)\"],[24,5,\"color: white;\"],[24,0,\"icon-help\"],[24,\"title\",\"About\"],[4,[38,0],[[32,0],\"openModal\",\"about_app\"],null],[12],[2,\"?\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],[\"modal\"],null]],null]],[2,\"\\n\\n\"],[10,\"footer\"],[14,0,\"l-footer\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"about\"],[12],[1,[30,[36,2],[[30,[36,1],[\"about_you\"],null]],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"logos\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"left\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"right\"],[12],[2,\"\\n      \\n      \"],[10,\"a\"],[14,6,\"https://github.com/hadamard\"],[14,0,\"github\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"span\"],[12],[2,\"Github\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/application.hbs"
    }
  });

  _exports.default = _default;
});