define("sharedrop/templates/about-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYRyrbqP",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Hadamard Hub lets you transfer files and Cryptocurrencies with others.\\nOther people will see you as \"],[10,\"b\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\".\\n\"]],\"hasEval\":false,\"upvars\":[\"you\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/about-you.hbs"
    }
  });

  _exports.default = _default;
});